import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Routes from './routes';

import { AnalyticsContextProvider } from './analytics/AnalyticsContext';
import { UserProvider } from './context/userContext';

import './App.css';
import { setGlobalHeaders } from './utils/setGlobalHeaders';
import axios from 'axios';
import { AuthProvider } from './context/authContext/authContext';
import { TAuthConfig } from './context/authContext/authTypes';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useClearCacheCtx } from 'react-clear-cache';
import { getBaseUrls } from './utils/getBaseUrls';
import { KiteLoader } from '@kite/react-kite';

export interface IUserContext {
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  language: 'english' | 'spanish';
  changeLanguage: Dispatch<SetStateAction<any>>;
}

export const authConfig: TAuthConfig = {
  clientId: 'mobile_onboarding_microsite',
  tokenEndpoint: `${getBaseUrls().apiUrl}/auth/oauth/v2/token`,
  authorizationEndpoint: `${getBaseUrls().idmUrl}/login`,
  redirectUri: window.location.origin || '',
  logoutEndpoint: '',
  logoutRedirect: '',
  accountType: 'RESIDENTIAL',
};

const App = () => {
  window.open(
    `https://www.spectrum.net/support/mobile/spectrum-mobile-getting-started-checklist`,
    '_self'
  );
  return <></>;
  // return <ComingSoon />;
  // const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  // const [ip, setIp] = useState('');
  // const [ipError, setIpError] = useState(false);
  // const [versionCheck, setVersionCheck] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const ip = await axios.get('//api.ipify.org?format=json');
  //       setGlobalHeaders({ 'X-Forwarded-For': ip?.data?.ip });

  //       if (ip?.data?.ip) {
  //         setIp(ip?.data?.ip);
  //       } else {
  //         setIpError(true);
  //       }
  //     } catch (error) {
  //       console.log(`error`, error);
  //       setIpError(true);
  //     }
  //   })();
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (!isLatestVersion) {
  //       await emptyCacheStorage();
  //     }
  //     setVersionCheck(true);
  //   })();
  //   // eslint-disable-next-line
  // }, []);

  // const queryClient = new QueryClient();

  // if (!versionCheck) {
  //   return <KiteLoader />;
  // }

  // return (
  //   <>
  //     {(ip || ipError) && (
  //       <QueryClientProvider client={queryClient}>
  //         <AnalyticsContextProvider>
  //           <AuthProvider authConfig={authConfig}>
  //             <UserProvider>
  //               <div className="App">
  //                 <Routes />
  //               </div>
  //             </UserProvider>
  //           </AuthProvider>
  //         </AnalyticsContextProvider>
  //       </QueryClientProvider>
  //     )}
  //   </>
  // );
};

export default App;
